import React from 'react';
import { observer } from 'mobx-react-lite';
import { FlatList, Input, Box } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { ListRenderItem, TouchableOpacity } from 'react-native';
import { Loading } from '@base/components/Loading';
import { useGroupsListQuery } from '@group/hooks/useGroupsListQuery';
import GroupItem from '@group/components/group-item';
import { IGroupData } from '@types';

const GroupList = (): React.ReactElement => {
  const navigation = useNavigation();

  const { groups, search, setSearch, isLoading } = useGroupsListQuery();

  const handleSelectGroup = (item: IGroupData): void => {
    navigation.navigate('GroupDetails', item);
  };

  const renderItem: ListRenderItem<IGroupData> = ({ item }) => {
    return (
      <TouchableOpacity onPress={() => handleSelectGroup(item)}>
        <GroupItem
          type={item.type}
          day={item.day}
          members={item.members}
          address={item.address}
          address_neighborhood={item.address_neighborhood}
          leader_id={item.leader_id}
          scheduled={item.scheduled}
        />
      </TouchableOpacity>
    );
  };

  return (
    <Box justifyContent="center" flex={1} px={2} m={3}>
      <Input
        placeholder="Pesquise por um grupo"
        placeholderTextColor="#888"
        value={search}
        onChangeText={setSearch}
        size="2xl"
        backgroundColor="white"
        mb={3}
      />
      {isLoading && <Loading />}
      <FlatList
        data={groups}
        keyExtractor={(_, index) => index.toString()}
        renderItem={renderItem}
        contentContainerStyle={{ paddingBottom: 40 }}
        mb={4}
        h={'100%'}
        ListEmptyComponent={<Box h={'100%'} />}
      />
    </Box>
  );
};

export const PgList = observer(GroupList);
