import { ScrollView } from 'native-base';
import { useRoute } from '@react-navigation/native';
import { PersonInformation } from '../../components/person-information';
import PersonGroup from './person-group';

export const PersonDetails = (): JSX.Element => {
  const routes = useRoute();
  const item = routes.params;

  return (
    <ScrollView m={4}>
      <PersonInformation item={item} />
      <PersonGroup />
    </ScrollView>
  );
};
