import React from 'react';
import { Box, Divider, Heading, HStack, Text, View, VStack } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import Field from '../../../../base/components/field';
import { usePersonGroupsQuery } from '../../hooks/usePersonGroupsQuery';
import { useRemovePersonGroup } from '../../hooks/useRemovePersonGroup';
import { IMemberData, IGroupData } from '@types';

const getGroupLeader = (group: IGroupData): IMemberData =>
  group.members.filter(member => member.role === 'Líder')[0];

const PersonGroup = (): React.ReactElement => {
  const navigation = useNavigation();
  const routes = useRoute();
  const person = routes.params;
  const { personGroups } = usePersonGroupsQuery(person?._id);
  const { removePersonGroup } = useRemovePersonGroup(person?._id);

  const onDeletePress = (group: any) => {
    removePersonGroup(group._id);
  };

  return (
    <Box backgroundColor="white" mt={4} p={4} borderRadius={4}>
      <HStack justifyContent="space-between">
        <Heading size="md">Pequenos Grupos ({personGroups?.length})</Heading>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate('PersonAddGroup', { ...person, personGroups })
          }
        >
          <Ionicons name="add-circle-outline" size={28} color="black" />
        </TouchableOpacity>
      </HStack>
      {personGroups?.map((group: any) => (
        <View key={group._id}>
          <Divider my={2} />
          <HStack justifyContent="space-between">
            <VStack key={group._id} flex={6}>
              <HStack justifyContent={'space-between'}>
                <Field name="Tipo" value={group.type} />
                <Field name="Líder" value={getGroupLeader(group).name} />
              </HStack>
              <Field name="Dia da semana" value={group.day} />
              <Field name="Endereço" value={group.address} />
            </VStack>
            <VStack
              bg="red"
              flex={1}
              ml={4}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {getGroupLeader(group).person_id !== person._id && (
                <TouchableOpacity onPress={() => onDeletePress(group)}>
                  <Ionicons name="trash" size={20} color="red" />
                </TouchableOpacity>
              )}
            </VStack>
          </HStack>
        </View>
      ))}

      {personGroups?.length === 0 && (
        <Text color="gray.500">Não está em nenhum pequeno grupo</Text>
      )}
    </Box>
  );
};

export default PersonGroup;
