import { deleteGroupService } from '@group/services';
import { mutate } from 'swr';

export const useRemoveGroups = (group_id: string) => {
  const removeGroup = async () => {
    await deleteGroupService(group_id);
    await mutate('groups');
  };

  return { removeGroup };
};
