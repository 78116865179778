import { mutate } from 'swr';
import { IGroupData } from '../../../types';
import { createGroupService } from '../services';

export const useCreateGroup = () => {
  const createGroup = async (group: IGroupData): Promise<void> => {
    await createGroupService(group);
    await mutate('groups');
  };

  return {
    createGroup,
  };
};
