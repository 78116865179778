import useSWR from 'swr';
import { IPersonData } from '../../../types';
import { fetchPersonById } from '../services';

export const usePerson = (id: string) => {
  const { data, isLoading } = useSWR(['persons', id], () =>
    fetchPersonById(id),
  );

  return {
    person: data as IPersonData,
    isLoading,
  };
};
