import { get } from '@base/services/api';
import { IEventData } from '@types';

export const getCountPerson = async (): Promise<number> => {
  const { data } = await get('/persons/howMany');
  return data;
};

export const getCountGroup = async (): Promise<number> => {
  const { data } = await get('/groups/howMany');
  return data;
};

