import { HomeHeader } from '../components/header';
import { Box, Center, FlatList } from 'native-base';
import { SummaryList } from '../components/summary-list';
import { useGetRecentEvents } from 'src/modules/activity/hooks/useGetRecentEvents';
import { ListRenderItem } from 'react-native';
import { IEventData } from '@types';
import MessageComponent from 'src/modules/activity/components/message_component';


const renderEvent:ListRenderItem<IEventData> = ({item}) => {
  return <MessageComponent {...item} />
}
export const Home = () => {

const { lastThreeEvents } = useGetRecentEvents()

  return (
    <Box mt={4}>
      <HomeHeader />
      <SummaryList />
      <Center>
        <FlatList renderItem={renderEvent} data={lastThreeEvents}/>
      </Center>
    </Box>
  );
};
