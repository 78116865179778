import { get, patch, post } from '../../base/services/api';
import { IPersonData } from '../../types';

export const fetchPersonList = async (search?: string, page?: number) => {
  if (!!search) {
    const { data } = await get(`/persons/search/${search};10;${page}`);
    return data;
  }
  const { data } = await get(`/persons/10;${page}`);
  return data;
};

export const fetchPersonGroups = async (id: string) => {
  const { data } = await get(`/persons/belongsToGroups/${id}`);
  return data;
};

export const addPersonToGroup = async (groupId: string, personId: string) => {
  await patch('/groups/addPersonOn', {
    group_id: groupId,
    person_id: personId,
  });
};

export const postNewPerson = async (personToPost: IPersonData) => {
  await post('/persons/create', personToPost);
};

export const editPersonService = async (personToEdit: IPersonData) => {
  await patch('/persons/patch', personToEdit);
};

export const fetchPersonById = async (id: string) => {
  const { data } = await get(`/persons/findOne/${id}`);
  return data;
};
