import { ScrollView } from 'native-base';
import { MembersInformation } from '@group/components/members-information';
import { GroupInformation } from '@group/components/group-information';

export default function GroupDetails(): JSX.Element {
  return (
    <ScrollView m={4}>
      <GroupInformation />
      <MembersInformation />
    </ScrollView>
  );
}
