import React from 'react';
import { HStack } from 'native-base';
import ItemCard from '@base/components/itemCard';
import Field from '@base/components/field';
import getGroupLeader from '@group/utils/getGroupLeader';
import { IGroupData } from '@types';

const GroupItem = ({
  type,
  members,
  day,
  address,
  address_neighborhood,
  scheduled,
}: Partial<IGroupData>): JSX.Element => {
  const group: IGroupData = { members };
  const groupLeader = getGroupLeader(group);
  return (
    <ItemCard>
      <HStack justifyContent={'space-between'}>
        <Field name="Rede" value={type} />
        <Field name="Líder" value={groupLeader?.name} />
      </HStack>
      <Field name="Dia da semana" value={day} />
      <Field name="Horário" value={scheduled} />
      <Field name="Bairro" value={address_neighborhood} />
      <Field name="Rua" value={address} />
    </ItemCard>
  );
};

export default GroupItem;
