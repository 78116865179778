import { Box, Button, Input, Text } from 'native-base';
import { useState } from 'react';
import { Image } from 'native-base';

export const LoginScreen = ({ navigation }: any) => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const passPhoneToCodeScreen = () => {
    navigation.navigate('code');
  };
  return (
    <Box flex={1} alignItems="center" justifyContent="center" m={12}>
      <Image
        resizeMode="stretch"
        source={{
          uri: 'https://igrejasaldaterra.org/wp-content/uploads/2020/06/cropped-logo-sal-da-terra-preta.png',
        }}
        alt="SdtImg"
        width="100%"
        height={20}
      />

      <Input
        margin={8}
        borderRadius={5}
        borderColor={'black'}
        size="2xl"
        width="100%"
        backgroundColor="transparent"
        focusOutlineColor="#888"
        placeholderTextColor={'black'}
        placeholder="Numero do celular"
        onChangeText={setPhone}
        value={phone}
      />

      <Input
        margin={8}
        borderRadius={5}
        borderColor={'black'}
        size="2xl"
        width="100%"
        backgroundColor="transparent"
        focusOutlineColor="#888"
        placeholderTextColor={'black'}
        placeholder="Password"
        onChangeText={setPassword}
        value={password}
      />
      <Button
        backgroundColor={'black'}
        color={'black'}
        width="100%"
        size="lg"
        onPress={passPhoneToCodeScreen}
      >
        Entrar
      </Button>
    </Box>
  );
};
