import useSWR from 'swr';
import { fetchGroup } from '../services';

export const useGetGroupMembers = (groupId: string) => {
  const { data, isLoading } = useSWR([`groups`, groupId], () => fetchGroup(groupId));

  return {
    members: data?.members,
    isLoading
  };
};
