import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { PersonScreen } from './screens/person-list';
import { PersonDetails } from './screens/details';
import { PersonAddGroup } from './screens/details/person-add-group';
import PersonForm from './screens/person-form';
import { ModalCloseButton } from '../../navigation/modal-close-button';

const Stack = createNativeStackNavigator();

export const PersonNavigation = (): JSX.Element => {
  return (
    <Stack.Navigator initialRouteName="PersonList">
      <Stack.Screen
        name="PersonList"
        component={PersonScreen}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="PersonForm"
        component={PersonForm}
        options={{
          headerShown: true,
          title: 'Cadastrar Pessoa',
          presentation: 'modal',
          headerRight: () => <ModalCloseButton />,
        }}
      />

      <Stack.Screen
        name="PersonDetails"
        component={PersonDetails}
        options={{
          title: 'Detalhes da pessoa',
          headerBackTitle: 'Lista',
          presentation: 'modal',
          headerRight: () => <ModalCloseButton />,
        }}
      />
      <Stack.Screen
        name="PersonAddGroup"
        component={PersonAddGroup}
        options={{
          title: 'Escolher Pequeno Grupo',
          headerBackTitle: 'Lista',
          presentation: 'modal',
          headerRight: () => <ModalCloseButton />,
        }}
      />
    </Stack.Navigator>
  );
};
