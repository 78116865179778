import React, { useLayoutEffect, useEffect } from 'react';
import {
  Button,
  CheckIcon,
  FormControl,
  Input,
  Select,
  WarningOutlineIcon,
} from 'native-base';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { subYears, parse, isDate, format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation, useRoute } from '@react-navigation/native';
import { TextInputMask } from 'react-native-masked-text';
import Location from '@base/services/location';
import FormControlInput from '@base/components/FormControl';
import { useCreatePerson } from '@person/hooks/useCreatePerson';
import { useEditPerson } from '@person/hooks/useEditPerson';
import { IPersonData } from '@types';
import {
  formattedDaysOfTheWeek,
  formattedTypeOptions,
} from '@group/components/select-constants';
import {
  churchOptions,
  formattedChurchOptions,
} from '@person/select-constants';
import { Platform } from 'react-native';

interface IFormattedPersonData extends IPersonData {
  person_id: string;
}

const schema = yup
  .object({
    birthdate: yup
      .date()
      .required('Data de nascimento é obrigatório')
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue)
          ? originalValue
          : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .max(new Date(), 'Não  pode usar datas futuras')
      .min(subYears(new Date(), 120), 'Insira uma data válida')
      .typeError('Insira uma data válida'),
    name: yup.string().required('Nome é obrigatório'),
    phone: yup
      .string()
      .required('Telefone é obrigatório')
      .min(16, 'Insira todo o número de telefone com o DDD'),
    address_neighborhood: yup.string().required('Bairro é obrigatório'),
    address_street: yup.string().required('Rua é obrigatório'),
    address_zipCode: yup
      .string()
      .required('CEP é obrigatório')
      .min(9, 'CEP tem que ter 8 digítos'),
    address_number: yup
      .string()
      .required('Número da casa/apartamento é obrigatório'),
    church: yup.string().required('Igreja é obrigatório'),
  })
  .required();

const PersonForm = (): React.ReactElement => {
  const routes = useRoute();
  const personToEdit = routes.params as IPersonData;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm<IPersonData>({
    resolver: yupResolver(schema),
    defaultValues: {
      birthdate: personToEdit ? personToEdit.birthdate : '',
      name: personToEdit ? personToEdit.name : '',
      phone: personToEdit ? personToEdit.phone.replace('+55', '') : '',
      address_neighborhood: personToEdit
        ? personToEdit.address_neighborhood
        : '',
      address_complement: personToEdit ? personToEdit.address_complement : '',
      address_number: personToEdit ? personToEdit.address_number : '',
      address_street: personToEdit ? personToEdit.address_street : '',
      address_zipCode: personToEdit ? personToEdit.address_zipCode : '',
      church: personToEdit ? personToEdit.church : churchOptions[0],
    },
  });

  const navigation = useNavigation();
  const { createPerson } = useCreatePerson();
  const postingPerson = async (data: IPersonData): Promise<void> => {
    const formattedData = {
      ...data,
      birthdate: format(data.birthdate as Date, 'dd/MM/yyyy'),
    };
    await createPerson(formattedData);
    navigation.goBack();
  };

  const { editPerson } = useEditPerson();

  const handleEditPerson = async (data: IPersonData): Promise<void> => {
    const formattedPerson: IFormattedPersonData = {
      ...data,
      person_id: personToEdit?._id,
      birthdate: format(data.birthdate as Date, 'dd/MM/yyyy'),
    };
    await editPerson(formattedPerson);
    navigation.goBack();
  };

  const cep = watch('address_zipCode');

  useEffect(() => {
    const onChangeCep = async (): Promise<void> => {
      if (cep && cep.length > 7) {
        const cepInformation = await Location.getInfoByCep({
          cepNumber: cep.replace('-', ''),
        });

        setValue('address_street', cepInformation.street || '', {
          shouldValidate: true,
        });

        setValue('address_neighborhood', cepInformation.neighborhood || '', {
          shouldValidate: true,
        });
      }
    };
    void onChangeCep();
  }, [cep, setValue]);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: personToEdit ? 'Editar Pessoa' : 'Cadastrar Pessoa',
    });
  }, [personToEdit]);

  return (
    <KeyboardAwareScrollView
      enableAutomaticScroll
      style={{
        paddingBottom: 16,
        paddingHorizontal: 16,
        flex: 1,
        backgroundColor: 'white',
      }}
    >
      <FormControlInput
        errors={errors}
        control={control}
        label="Nome"
        name="name"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onBlur={onBlur}
            onChangeText={onChange}
            value={value?.toString()}
            size={'xl'}
            placeholder="Digite o nome da pessoa"
          />
        )}
      />

      <FormControlInput
        errors={errors}
        control={control}
        label="Telefone"
        name="phone"
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputMask
            onBlur={onBlur}
            onChangeText={onChange}
            value={value?.toString()}
            placeholder="Insira o telefone da pessoa com o DDD"
            customTextInput={Input}
            type="custom"
            keyboardType="number-pad"
            customTextInputProps={{
              size: 'xl',
            }}
            options={{
              mask: '(99) 9 9999-9999',
            }}
          />
        )}
      />

      <FormControlInput
        errors={errors}
        control={control}
        label="Data de Nascimento"
        name="birthdate"
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputMask
            onBlur={onBlur}
            onChangeText={onChange}
            value={value?.toString()}
            placeholder="Insira a data de nascimento da pessoa"
            customTextInput={Input}
            type="custom"
            keyboardType="number-pad"
            customTextInputProps={{
              size: 'xl',
            }}
            options={{
              mask: '99/99/9999',
            }}
          />
        )}
      />

      <FormControlInput
        errors={errors}
        control={control}
        label="CEP"
        name="address_zipCode"
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputMask
            customTextInput={Input}
            onBlur={onBlur}
            onChangeText={onChange}
            type="zip-code"
            value={value?.toString()}
            customTextInputProps={{
              size: 'xl',
            }}
            placeholder="Digite o CEP da pessoa"
          />
        )}
      />

      <FormControlInput
        errors={errors}
        control={control}
        label="Rua"
        name="address_street"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onBlur={onBlur}
            onChangeText={onChange}
            value={value?.toString()}
            size={'xl'}
            placeholder="Digite a rua da pessoa"
          />
        )}
      />

      <FormControlInput
        errors={errors}
        control={control}
        label="Bairro"
        name="address_neighborhood"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onBlur={onBlur}
            onChangeText={onChange}
            value={value?.toString()}
            size={'xl'}
            placeholder="Digite o bairro da pessoa"
          />
        )}
      />

      <FormControlInput
        errors={errors}
        control={control}
        label="Número"
        name="address_number"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onBlur={onBlur}
            onChangeText={onChange}
            value={value?.toString()}
            size={'xl'}
            placeholder="Digite o número da casa/apartamento da pessoa"
          />
        )}
      />

      <FormControl>
        <FormControl.Label>Complemento</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onBlur={onBlur}
              onChangeText={onChange}
              value={value?.toString()}
              size={'xl'}
              placeholder="Digite o complemento da casa da pessoa (se tiver)"
            />
          )}
          name="address_complement"
        />
        {errors.name != null && (
          <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
            {errors?.name?.message}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
      <FormControlInput
        errors={errors}
        control={control}
        label="Igreja"
        name="church"
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            selectedValue={value}
            accessibilityLabel="Selecione a igreja"
            placeholder="Selecione a igreja"
            size={'xl'}
            _selectedItem={{
              endIcon: <CheckIcon size="5" />,
            }}
            onValueChange={selectedDay => {
              setValue('church', selectedDay);
            }}
            _actionSheet={{
              useRNModal: Platform.OS === 'ios',
            }}
          >
            {formattedChurchOptions.map(church => (
              <Select.Item
                label={church.label}
                value={church.label}
                key={church.index}
              />
            ))}
          </Select>
        )}
      />

      <Button
        mt={4}
        onPress={
          personToEdit
            ? handleSubmit(handleEditPerson)
            : handleSubmit(postingPerson)
        }
        backgroundColor="blue.500"
        size="lg"
        mb={4}
      >
        {personToEdit ? 'Salvar' : 'Cadastrar'}
      </Button>
    </KeyboardAwareScrollView>
  );
};

export default PersonForm;
