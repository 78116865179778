import React from 'react';
import { Box, IBoxProps } from 'native-base';

const ItemCard = (props: IBoxProps): React.ReactElement => {
  return (
    <Box
      {...props}
      marginTop={3}
      backgroundColor="white"
      borderRadius="sm"
      shadow={1}
      p={3}
      mx={0.5}
    ></Box>
  );
};
export default ItemCard;
