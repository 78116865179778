import { postNewPerson } from '../services';
import { mutate } from 'swr';
import { IPersonData } from '../../../types';

export const useCreatePerson = () => {
  const createPerson = async (person: IPersonData) => {
    await postNewPerson(person);
    await mutate('persons');
  };

  return {
    createPerson,
  };
};
