import { NavigationContainer } from '@react-navigation/native';
import * as Localization from 'expo-localization';
import { observer } from 'mobx-react-lite';
import { NativeBaseProvider } from 'native-base';
import 'react-native-gesture-handler';
import {
  useFonts,
  Inter_100Thin,
  Inter_200ExtraLight,
  Inter_300Light,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from '@expo-google-fonts/inter';
import { i18n } from '@base/translations/translate';
import { AppNavigation } from './src/navigation';
import { SWRConfig } from 'swr';
import { Loading } from '@base/components/Loading';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { StatusBar } from 'expo-status-bar';

i18n.locale = Localization.locale;
function App(): JSX.Element {
  const [fontsLoaded] = useFonts({
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
        revalidateIfStale: true,
        focusThrottleInterval: 5000,
      }}
    >
      <NavigationContainer>
        <NativeBaseProvider>
          {fontsLoaded ? (
            <SafeAreaProvider>
              <StatusBar style="dark" />
              <AppNavigation />
            </SafeAreaProvider>
          ) : (
            <Loading />
          )}
        </NativeBaseProvider>
      </NavigationContainer>
    </SWRConfig>
  );
}

export default observer(App);
