import { Pressable } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

export const ModalCloseButton = () => {
  const navigation = useNavigation();
  return (
    <Pressable
      onPress={() => navigation.goBack()}
      color="#841584"
      accessibilityLabel="Close modal"
    >
      <MaterialIcons name={'close'} size={32} color={'#000'} />
    </Pressable>
  );
};
