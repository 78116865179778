import React from 'react';
import { HStack } from 'native-base';
import Field from '../../../base/components/field';
import ItemCard from '../../../base/components/itemCard';
import { IGroupData } from '../../group/screens/group-list';

const PersonAddGroupItem = ({
  group,
}: {
  group: IGroupData;
}): React.ReactElement => {
  const groupLeader = group?.members?.filter(
    (member: any) => member.role === 'Líder',
  )[0];

  return (
    <ItemCard>
      <HStack justifyContent={'space-between'}>
        <Field name="Rede" value={group.type as string} />
        <Field name="Líder" value={groupLeader?.name} />
      </HStack>
      <Field name="Dia da semana" value={group.day as string} />
      <Field name="Endereço" value={group.address as string} />
    </ItemCard>
  );
};

export default PersonAddGroupItem;