import React from 'react';
import { Box, Divider, Heading, Text } from 'native-base';
import { useRoute } from '@react-navigation/native';
import { useGetGroupMembers } from '@group/hooks/useGetGroupMembers';
import { Loading } from '@base/components/Loading';
import { IMemberData, IGroupData } from '@types';

export const MembersInformation = (): React.ReactElement => {
  const routes = useRoute();
  const { _id: id } = routes.params as IGroupData;

  const { members, isLoading } = useGetGroupMembers(id);

  if (isLoading) return <Loading />;

  return (
    <Box bgColor={'white'} p={4} borderRadius={4}>
      <Heading size="md">Membros</Heading>
      <Divider my={2} />
      {members.map((member: IMemberData) => (
        <Box key={member.person_id} p={1}>
          <Text color="#warmGray.200" fontSize="lg">
            {member.name}
          </Text>
        </Box>
      ))}
    </Box>
  );
};
