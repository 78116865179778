import { Ionicons } from '@expo/vector-icons';
import { VStack, Box, Divider, Heading, HStack, Icon } from 'native-base';
import Field from '@base/components/field';
import { useNavigation, useRoute } from '@react-navigation/native';
import getGroupLeader from '@group/utils/getGroupLeader';
import { IGroupData } from '@types';
import { useRemoveGroups } from '@group/hooks/useRemoveGroups';

export const GroupInformation = (): JSX.Element => {
  const navigation = useNavigation();
  const routes = useRoute();

  const group = routes.params as IGroupData;

  const leader = getGroupLeader(group);

  const formattedGroup = { ...group, leader };

  const { removeGroup } = useRemoveGroups(group._id);

  const sendGroupInfo = (group: IGroupData): void => {
    navigation.navigate('GroupForm', group);
  };

  const onDeletePress = (): void => {
    void removeGroup();
    navigation.goBack();
  };

  return (
    <Box backgroundColor="white" p={4} borderRadius={4} mb={4}>
      <HStack justifyContent={'space-between'}>
        <Heading size="md">Pequenos Grupos</Heading>
        <Icon
          as={<Ionicons />}
          name="create-outline"
          size={28}
          color="black"
          onPress={() => sendGroupInfo(formattedGroup)}
        />
      </HStack>
      <Divider my={2} />
      <Field name="Rede" value={group.type} />
      <Field name="Líder" value={getGroupLeader(group).name} />
      <Field name="Dia da semana" value={group.day} />
      <Field name="Horário" value={group.scheduled} />
      <Field name="Bairro" value={group.address_neighborhood} />
      <Field name="Rua" value={group.address} />
      <VStack
        bg="red"
        flex={1}
        ml={4}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        {getGroupLeader(group).person_id !== group._id && (
          <Icon
            as={<Ionicons />}
            name="trash"
            size={'20px'}
            color="red.500"
            onPress={onDeletePress}
          />
        )}
      </VStack>
    </Box>
  );
};
