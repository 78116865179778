import { IPersonData, IGroupData, IEventData } from '@types'

const createGroupMessage = (groupType: string, eventTypeString: string, personName?: string) => {
  const title = !!personName? personName : groupType
  const body = eventTypeString.startsWith('R')? eventTypeString : eventTypeString + groupType
  return { title, body };
}

const createPersonMessage = (personName: string, eventTypeString: string) => {
  const title = personName;
  const body = eventTypeString;
  return { title, body };
}

const eventTypes = {
  GROUP_CREATED: 'Rede criada no sistema',
  GROUP_UPDATED: 'Rede atualizada no sistema',
  GROUP_DELETED: 'Caso especial',
  PERSON_ADDED: ' adicionado(a) na rede ',
  PERSON_CREATED: ' criado(a) no sistema',
  PERSON_ROLE_MODIFIED: 'Função da pessoa alterada na rede',
  PERSON_REMOVED: ' saiu da rede ',
  PERSON_UPDATED: ' atualizado(a) no sistema',
  
}

export const translateEvent = ({type, person, group}: {type: string, person?: IPersonData, group?: IGroupData}) => {
   const map = 
    {
     'PERSON_CREATED':  person && createPersonMessage(person.name, eventTypes['PERSON_CREATED']),
     'PERSON_UPDATED': person && createPersonMessage(person.name, eventTypes['PERSON_UPDATED']),
     'GROUP_CREATED' : group && createGroupMessage(group.type, eventTypes['GROUP_CREATED']),  
     'GROUP_UPDATED' : group && createGroupMessage(group.type, eventTypes['GROUP_UPDATED']),
     'PERSON_ADDED': person && group && createGroupMessage(group.type, eventTypes['PERSON_ADDED'], person.name),
     'PERSON_REMOVED': person && group && createGroupMessage(group.type, eventTypes['PERSON_REMOVED'], person.name),
     'PERSON_ROLE_MODIFIED': person && group && createGroupMessage(group.type, eventTypes['PERSON_ROLE_MODIFIED'], person.name),
    } 


    //@ts-ignore
    return map[type]
}

export const deleteTranslationEvent = (fullEvent:IEventData) => {

    const title = fullEvent.deletedGroupType
    const body = 'Um PG dessa rede foi deletado'
    
    return { body, title }
}