import axios from 'axios';
const instance = axios.create({
  baseURL: process.env.API_URL
    ? process.env.API_URL
    : 'https://saldaterrabackend.herokuapp.com',
});

export function get(path: string) {
  return instance.get(path);
}

export function post(path: string, data: object) {
  return instance.post(path, data);
}

export function axiosDelete(path: string, id: any) {
  return instance.delete(path, id);
}

export function put(path: string, data: object) {
  return instance.put(path, data);
}

export function patch(path: string, data: object) {
  return instance.patch(path, data);
}
