import { axiosDelete, get, patch, post } from '@base/services/api';
import { IGroupData } from '@types';

export const fetchGroupList = async (search?: string, page?: number) => {
  const { data } = await get(`/groups/`);
  return data;
};

export const createGroupService = async (newGroup: IGroupData) => {
  await post('/groups/create', {
    leader_id: newGroup.leader_id,
    type: newGroup.type,
    day: newGroup.day,
    address: newGroup.address,
    address_neighborhood: newGroup.address_neighborhood,
    scheduled: newGroup.scheduled,
  });
};

export const fetchGroup = async (groupId: string) => {
  const { data } = await get(`/groups/findOne/${groupId}`);
  return data;
};

export const editGroupService = async (groupToEdit: any) => {
  await patch('/groups/fix/', groupToEdit);
};

export const modifyMemberRoleService = async (dataToChange: any) => {
  await patch('/groups/modifyRole', dataToChange);
};

export const deleteGroupService = async (groupId: string) => {
  await axiosDelete(`/groups/delete/${groupId}`, {});
};
