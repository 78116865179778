import { mutate } from 'swr';
import { IGroupData } from '@types';
import { editGroupService } from '@group/services';

export const useEditGroup = () => {
  const editGroup = async (group: IGroupData): Promise<void> => {
    await editGroupService(group);
    await mutate('groups');
  };

  return {
    editGroup,
  };
};
