import React from 'react';

import { FormControl, WarningOutlineIcon } from 'native-base';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import {
  ControllerFieldState,
  ControllerRenderProps,
} from 'react-hook-form/dist/types/controller';

interface FormControlInputProps {
  control: Control<any, any>;
  errors: FieldErrors;
  label: string;
  name: string;
  render: ({
    field,
    fieldState,
    formState,
  }: {
    field: ControllerRenderProps;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<any>;
  }) => React.ReactElement;
}

const FormControlInput = ({
  errors,
  control,
  name,
  label,
  render,
}: FormControlInputProps): React.ReactElement => {
  return (
    <FormControl pb={1} isInvalid={name in errors}>
      <FormControl.Label>{label}</FormControl.Label>
      <Controller control={control} render={render} name={name} />
      {errors[name] != null && (
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          {errors?.[name]?.message}
        </FormControl.ErrorMessage>
      )}
    </FormControl>
  );
};

export default FormControlInput;
