import { PersonNavigation } from '@person/navigation';
import { PersonGroupNavigation } from '@group/navigation';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
const Tab = createMaterialTopTabNavigator();

export const RecordsNavigation = (): JSX.Element => {
  const insets = useSafeAreaInsets();

  return (
    <Tab.Navigator
      style={{
        paddingTop: insets.top,
        backgroundColor: '#fff',
      }}
    >
      <Tab.Screen
        name="Person"
        component={PersonNavigation}
        options={{ tabBarLabel: 'Pessoas' }}
      />
      <Tab.Screen
        name="Group"
        component={PersonGroupNavigation}
        options={{ tabBarLabel: 'Pequenos Grupos' }}
      />
    </Tab.Navigator>
  );
};
