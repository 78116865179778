import { Image, VStack, Text, Box, HStack } from 'native-base';
import headerLogo from '../../../assets/headerLogo.png';

export const HomeHeader = (): JSX.Element => {
  return (
    <VStack>
      <Box justifyContent={'center'} alignItems={'center'} mt={10}>
        <Image
          source={headerLogo}
          alt={headerLogo.toString()}
          width={160}
          height={35}
          resizeMode={'contain'}
        />
      </Box>
      <HStack mx={10} my={8}>
        <Text fontSize={20} fontFamily={'Inter_700Bold'} letterSpacing={1.2}>
          Bem-vindo, Adriano!
        </Text>
      </HStack>
    </VStack>
  );
};


