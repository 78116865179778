import { IPersonData } from '../../../types';
import { useEffect, useState } from 'react';
import { fetchPersonList } from '../services';
import useSWR from 'swr';

export const usePersonListQuery = () => {
  const [page, setPage] = useState(1);
  const [list, setList] = useState<IPersonData[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const { isLoading } = useSWR(
    ['persons', searchQuery, page],
    async () => await fetchPersonList(searchQuery, page),
    {
      onSuccess: (data: IPersonData[]) => {
        if (page !== 1 && !searchQuery) {
          setList(list => [...list, ...data]);
        } else {
          setList(data);
        }
      },
    },
  );

  const loadMorePersons = (): void => {
    if (list.length < 10 * page) return;
    setPage(old => old + 1);
  };

  return {
    persons: list,
    loadMorePersons,
    setSearchQuery,
    searchQuery,
    isLoading,
  };
};
