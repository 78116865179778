import { mutate, useSWRConfig } from 'swr';

export const useMutatePartialKey = (partialKey: string) => {
  const { cache } = useSWRConfig();
  const mutatePartial = () => {
    Array.from(cache.keys())
      .filter((cacheKey: string) => cacheKey.includes(partialKey))
      .forEach((key: string) => {
        mutate(key);
      });
  };
  return {
    mutatePartial,
  };
};
