const typeOptions = [
  'Misto',
  'Ensino',
  'Geral',
  'Kids',
  'Mulheres',
  'Homens',
  'Teens',
  'Casais',
  'Fotos',
  'Diaconia',
  'Música',
  'Artes',
  'Surdos',
  'Jovens',
];
export const formattedTypeOptions = typeOptions.map((types, index) => ({
  types,
  index,
}));

const daysOfTheWeek = [
  'Segunda-Feira',
  'Terça-Feira',
  'Quarta-Feira',
  'Quinta-Feira',
  'Sexta-Feira',
  'Sábado',
];
export const formattedDaysOfTheWeek = daysOfTheWeek.map((days, index) => ({
  days,
  index,
}));
