import { patch } from '../../../base/services/api';
import { mutate } from 'swr';

export const useRemovePersonGroup = (personId: string) => {
  const removePersonGroup = async (groupId: string) => {
    await patch('groups/removeMember', {
      group_id: groupId,
      member_id: personId,
    });
    mutate(['personGroups', personId]);
  };

  return { removePersonGroup };
};
