import { Ionicons } from '@expo/vector-icons';
import { Box, Divider, Heading, HStack, Pressable } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { usePerson } from '@person/hooks/usePerson';
import { Loading } from '@base/components/Loading';
import Field from '@base/components/field';
import { IPersonData } from '@types';

export const PersonInformation = ({ item }: { item: any }): JSX.Element => {
  const navigation = useNavigation();
  const sendPersonInfo = (item: IPersonData): void => {
    navigation.navigate('PersonForm', item);
  };

  const { person, isLoading } = usePerson(item._id);

  if (isLoading === true) return <Loading />;

  return (
    <Box backgroundColor="white" p={4} borderRadius={4}>
      <HStack justifyContent={'space-between'}>
        <Heading size="md">Dados Pessoais</Heading>
        <Pressable onPress={() => sendPersonInfo(person)}>
          <Ionicons name="create-outline" size={28} color="black"></Ionicons>
        </Pressable>
      </HStack>
      <Divider my={2} />
      <Field name={'Nome'} value={person.name} />
      <Field name={'Data de Nascimento'} value={person.birthdate} />
      <Field name={'Telefone'} value={person.phone} />
      <Field name={'Rua'} value={person.address_street} />
      <Field name={'Número'} value={person.address_number} />
      <Field name={'Bairro'} value={person.address_neighborhood} />
      <Field name={'CEP'} value={person.address_zipCode} />
      <Field name={'Complemento'} value={person.address_complement ?? ''} />
    </Box>
  );
};
