import useSWR from 'swr';
import { IGroupData, IMemberData } from '../../../types';
import { fetchGroupList } from '../../group/services';
import { useEffect, useState } from 'react';
const getGroupLeader = (group: IGroupData): IMemberData => {
  if (group.members.length === 0) return { role: '', name: '' , person_id:''};
  return group.members.filter(member => member.role === 'Líder')[0];
};

export const useAvailableGroupsToAdd = (person: any) => {
  const [search, setSearch] = useState('');
  const [availableGroups, setAvailableGroups] = useState<IGroupData[]>([]);
  

  const { data } = useSWR(`/groups/`, fetchGroupList, {
    onSuccess: setAvailableGroups,
  });

  useEffect(() => {
    if (search) {
      const filteredGroups = data
        .filter(
          (group: IGroupData) =>
            getGroupLeader(group)
              .name.toLowerCase()
              .includes(search.toLowerCase()) ||
            group.type.toLowerCase().includes(search.toLowerCase()) ||
            group.day.toLowerCase().includes(search.toLowerCase()),
        )
        .filter(
          (group: IGroupData) =>
            !person.personGroups.map((pg: IGroupData) => pg._id).includes(group._id),
        );
      setAvailableGroups(filteredGroups);
    } else if (!search) {
      setAvailableGroups(data);
    }
  }, [search]);

  return {
    availableGroups,
    search,
    setSearch,
  };
};
