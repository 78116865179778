import { Box, FlatList, Input } from 'native-base';
import { ListRenderItem, TouchableOpacity } from 'react-native';
import { observer } from 'mobx-react-lite';
import Field from '@base/components/field';
import ItemCard from '@base/components/itemCard';
import { Loading } from '@base/components/Loading';
import { usePersonListQuery } from '@person/hooks/usePersonListQuery';
import { IPersonData } from '@types';
import { useNavigation } from '@react-navigation/native';

const PersonScreenComponent = (): JSX.Element => {
  const navigation = useNavigation();
  const goToPersonDetails = (item: IPersonData): void =>
    navigation.navigate('PersonDetails', item);
  const renderItem: ListRenderItem<IPersonData> = ({ item }) => (
    <ItemCard>
      <TouchableOpacity onPress={() => goToPersonDetails(item)}>
        <Field name="Nome" value={item.name} />
        <Field name="Telefone" value={item.phone} />
        <Field name="Data De Nascimento" value={item.birthdate} />
      </TouchableOpacity>
    </ItemCard>
  );

  const { persons, loadMorePersons, setSearchQuery, searchQuery } =
    usePersonListQuery();
  return (
    <Box px={2} m={3} flex={1}>
      <Input
        placeholder="Pesquise uma pessoa"
        placeholderTextColor="#888"
        value={searchQuery}
        onChangeText={setSearchQuery}
        size="2xl"
        backgroundColor="white"
        mb={3}
      />
      <FlatList
        data={persons}
        keyExtractor={item => item._id}
        renderItem={renderItem}
        onEndReached={loadMorePersons}
        onEndReachedThreshold={0.1}
        h={'100%'}
        ListEmptyComponent={<Box h={'100%'} />}
      />
    </Box>
  );
};

export const PersonScreen = observer(PersonScreenComponent);
