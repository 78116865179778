import { Box, Text } from 'native-base';

export const SummaryCard = ({ count, message }): JSX.Element => {
  return (
    <Box
      bgColor={'gray.300'}
      justifyContent={'center'}
      alignItems={'center'}
      w="140px"
      h="140px"
      borderRadius={'6px'}
      mx={4}
      p={1}
    >
      <Text fontFamily={'Inter_900Black'} fontSize={'2xl'} color={'black'}>
        {count}
      </Text>
      <Text fontFamily={'Inter_500Medium'} fontSize={'sm'} color={'black'}>
        {message}
      </Text>
    </Box>
  );
};
