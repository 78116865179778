import React from 'react';
import { Box, Text } from 'native-base';
import { usePerson } from '@person/hooks/usePerson';
import { useGetOneGroup } from '@group/hooks/useFetchOneGroup';
import { IEventData } from '@types';
import { deleteTranslationEvent, translateEvent } from '../hooks/useTranslateEvent';


const MessageComponent = (item:IEventData) => {


  const { person } = usePerson(item.person_id)
  const { group } = useGetOneGroup(item.group_id)


  const message =!!item.deletedGroupType? deleteTranslationEvent(item) : translateEvent({type: item.type, person, group})

  return (
        <Box backgroundColor="gray.100" p={3} rounded={10} flex={1} mt={2}>
          <Text fontSize={16} fontWeight="bold">
            {message?.title}
          </Text>
          <Text fontSize={14} color="black">
            {message?.body}
          </Text>
        </Box>
  );
};

export default MessageComponent;
