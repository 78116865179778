import cep from 'cep-promise';

interface ICepInformations {
  cep?: string;
  state?: string;
  city?: string;
  street?: string;
  neighborhood?: string;
}
class Location {
  static async getInfoByCep({
    cepNumber,
  }: {
    cepNumber: string;
  }): Promise<ICepInformations> {
    return await new Promise((resolve, reject) => {
      (async () => {
        try {
          cep(cepNumber).then((dados: ICepInformations) => {
            resolve(dados);
          });
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}

export default Location;
