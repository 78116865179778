import React from 'react';
import { Center, Spinner } from 'native-base';

export function Loading(): React.ReactElement {
  return (
    <Center h="100%">
      <Spinner color="black" size={40} />
    </Center>
  );
}
