import React from 'react';
import { ListRenderItem, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Box, FlatList, Input, VStack } from 'native-base';
import { observer } from 'mobx-react-lite';
import ItemCard from '@base/components/itemCard';
import Field from '@base/components/field';
import { usePersonListQuery } from '@person/hooks/usePersonListQuery';

const SelectLeaderToGroup = (): React.ReactElement => {
  const navigation = useNavigation();

  const { persons, loadMorePersons, setSearchQuery, searchQuery } =
    usePersonListQuery();

  const onSelectPerson = (selectedLeader: any): void => {
    navigation.navigate('GroupForm', {
      name: selectedLeader.name,
      person_id: selectedLeader._id,
    });
  };

  const renderItem: ListRenderItem<any> = ({ item }) => (
    <ItemCard>
      <Box pt={4}>
        <TouchableOpacity onPress={() => onSelectPerson(item)}>
          <Field name="Nome" value={item.name} />
          <Field name="Telefone" value={item.phone} />
          <Field name="Data de Nascimento" value={item.birthdate} />
        </TouchableOpacity>
      </Box>
    </ItemCard>
  );

  return (
    <VStack>
      <Input
        placeholder="Pesquise por uma pessoa para liderar o grupo"
        placeholderTextColor="#888"
        value={searchQuery}
        onChangeText={setSearchQuery}
        size="2xl"
        backgroundColor="white"
      />

      <FlatList
        contentContainerStyle={{ paddingBottom: 55 }}
        data={persons}
        renderItem={renderItem}
        onEndReached={loadMorePersons}
      />
    </VStack>
  );
};

export const SelectLeader = observer(SelectLeaderToGroup);
