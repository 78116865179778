import { getCountPerson } from '../services';
import useSWR from 'swr';

export const useCountPersons = () => {
  const { isLoading, data } = useSWR(
    'countPersons',
    async () => await getCountPerson(),
  );
  return { data, isLoading };
};
