import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AuthNavigation } from '../modules/auth/navigation';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { observer } from 'mobx-react-lite';
import TabBarIcon from './tab-bar-icon';
import { PlusBarIcon } from './plus-bar-icon';
import { Empty } from './empty';
import { RecordsNavigation } from '../modules/records/navigation';
import PersonForm from '@person/screens/person-form';
import { MaterialIcons } from '@expo/vector-icons';
import GroupForm from '@group/screens/group-form';
import { ModalCloseButton } from './modal-close-button';
import { Home } from '../modules/home/screens';
import { SelectLeader } from '@group/screens/choose-leader-to-group';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

export const TabNavigation = (): JSX.Element => {
  return (
    <Tab.Navigator
      initialRouteName="Pessoas"
      screenOptions={({ route }) => ({
        headerShown: false,
        tabBarActiveTintColor: 'black',
        tabBarInactiveTintColor: 'gray',
        tabBarStyle: {
          padding: 1,
          height: 80,
          backgroundColor: 'white',
          borderTopWidth: 0,
          elevation: 0,
        },
      })}
    >
      <Tab.Screen
        name="Home"
        component={Home}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon name={'home'} focused={focused} />
          ),
        }}
      />

      <Tab.Screen
        name={'add'}
        component={Empty}
        options={{
          tabBarIcon: ({ focused }) => <PlusBarIcon />,
          tabBarLabel: () => null,
        }}
        listeners={{
          tabPress: e => {
            e.preventDefault();
          },
        }}
      />

      <Tab.Screen
        name="Records"
        component={RecordsNavigation}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon name={'list'} focused={focused} />
          ),
          headerShown: false,
          tabBarLabel: 'Registros',
        }}
      />
    </Tab.Navigator>
  );
};

const StackNavigation = (): JSX.Element => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="App" component={TabNavigation} />
      <Stack.Screen name="Auth" component={AuthNavigation} />
      <Stack.Screen
        name="PersonForm"
        component={PersonForm}
        options={{
          headerShown: true,
          title: 'Cadastrar Pessoa',
          presentation: 'modal',
          headerRight: () => <ModalCloseButton />,
        }}
      />
      <Stack.Screen
        name="GroupForm"
        component={GroupForm}
        options={{
          headerShown: true,
          presentation: 'modal',
          headerRight: () => <ModalCloseButton />,
          title: 'Adicionar PG',
        }}
      />
      <Stack.Screen
        name="LinkLeaderToGroup"
        component={SelectLeader}
        options={{
          presentation: 'modal',
          title: 'Escolher Líder',
          headerShown: true,
        }}
      />
    </Stack.Navigator>
  );
};

export const AppNavigation = observer(StackNavigation);
