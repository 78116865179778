import React from 'react';
import { Box, Text } from 'native-base';

interface FieldProps {
  name: string;
  value: string;
}

const Field = ({ name, value }: FieldProps): React.ReactElement => {
  return (
    <Box p={1}>
      <Text color="gray.500" fontSize="md">
        {name}
      </Text>
      <Text color="#warmGray.200" fontSize="lg">
        {value}
      </Text>
    </Box>
  );
};

export default Field;
