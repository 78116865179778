import { createNativeStackNavigator } from '@react-navigation/native-stack';
import GroupForm from './screens/group-form';
import { PgList } from './screens/group-list';
import { SelectLeader } from './screens/choose-leader-to-group';
import GroupDetails from './screens/details';
import { ModalCloseButton } from '../../navigation/modal-close-button';

const Stack = createNativeStackNavigator();

export const PersonGroupNavigation = (): JSX.Element => {
  return (
    <Stack.Navigator initialRouteName="PersonGroupList">
      <Stack.Screen
        name="PersonGroupList"
        component={PgList}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="GroupForm"
        component={GroupForm}
        options={{
          headerShown: true,
          presentation: 'modal',
          headerRight: () => <ModalCloseButton />,
          title: 'Adicionar PG',
        }}
      />
      <Stack.Screen
        name="LinkLeaderToGroup"
        component={SelectLeader}
        options={{
          presentation: 'modal',
          title: 'Escolher Líder',
          headerBackTitle: 'Voltar',
          headerRight: () => <ModalCloseButton />,
        }}
      />
      <Stack.Screen
        name="GroupDetails"
        component={GroupDetails}
        options={{
          presentation: 'modal',
          title: 'Pequeno Grupo',
          headerRight: () => <ModalCloseButton />,
        }}
      />
    </Stack.Navigator>
  );
};
