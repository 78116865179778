import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Select, Button, Pressable, Input, Icon, CheckIcon } from 'native-base';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigation, useRoute } from '@react-navigation/native';
import { TextInputMask } from 'react-native-masked-text';
import { AntDesign } from '@expo/vector-icons';
import { format, isDate, parse } from 'date-fns';
import {
  formattedDaysOfTheWeek,
  formattedTypeOptions,
} from '@group/components/select-constants';
import FormControlInput from '@base/components/FormControl';
import { useCreateGroup } from '@group/hooks/useCreateGroup';
import { useEditGroup } from '@group/hooks/useEditGroup';
import { useModifyRole } from '@group/hooks/userModifyRole';
import { IGroupData } from '@types';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Location from '@base/services/location';
import { Platform } from 'react-native';

const schema = yup.object({
  _id: yup.string(),
  leader_id: yup.string().required('O grupo precisa de um líder'),
  type: yup.string().required('O grupo precisa de uma rede'),
  address: yup.string().required('O grupo precisa de um endereço'),
  address_neighborhood: yup
    .string()
    .required('O grupo precisa estar em um bairro'),
  day: yup.string().required('Selecione o dia que o pequeno grupo ocorre'),
  scheduled: yup
    .date()
    .required('O horário de PG é necessário')
    .transform((value, originalValue) => {
      const parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, 'HH:mm', new Date());
      return parsedDate;
    })
    .typeError('Insira um horário válido'),
  address_zipCode: yup.string().max(9),
});

const GroupForm = (): React.ReactElement => {
  const [isEditing, setIsEditing] = useState(false);
  const [formattedGroup, setFormattedGroup] = useState({
    leader_id: '',
    name: '',
    type: '',
    address: '',
    day: '',
  });
  const navigation = useNavigation();
  const route = useRoute();

  const params = route.params as IGroupData & { leader: string };
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IGroupData>({
    resolver: yupResolver(schema),
    defaultValues: {
      address: params ? params.address : '',
      leader_id:
        params !== undefined && params.leader !== undefined
          ? params.leader.person_id
          : '',
      type: params ? params.type : '',
      day: params ? params.day : '',
      address_neighborhood: params ? params.address_neighborhood : '',
      address_zipCode: params ? params.address_zipCode : '',
      scheduled: params ? params.scheduled : '',
    },
  });

  const valueSetter = (): void => {
    setValue('day', formattedGroup.day);
    setValue('leader_id', formattedGroup.leader_id);
    setValue('type', formattedGroup.type);
  };

  const { modifyPersonRole } = useModifyRole();
  const { editGroup } = useEditGroup();
  const { createGroup } = useCreateGroup();

  const cep = watch('address_zipCode');

  useEffect(() => {
    if (params !== undefined && params.leader !== undefined) {
      setValue('_id', params._id);
      showSelectedLeader(params);
      setIsEditing(true);
      const leader = params.leader;
      setFormattedGroup({
        leader_id: leader.person_id,
        name: leader.name,
        type: params.type,
        address: params.address,
        day: params.day,
      });
    } else if (params !== undefined && params.leader === undefined) {
      showSelectedLeader(params);
    }
  }, [params]);

  useEffect(() => {
    const onChangeCep = async (): Promise<void> => {
      if (typeof cep !== 'undefined') {
        if (cep !== '' && cep.length > 7) {
          const cepInformation = await Location.getInfoByCep({
            cepNumber: cep.replace('-', ''),
          });

          setValue('address', cepInformation.street || '', {
            shouldValidate: true,
          });

          setValue('address_neighborhood', cepInformation.neighborhood || '', {
            shouldValidate: true,
          });
        }
      }
    };
    void onChangeCep();
  }, [cep, setValue]);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: isEditing ? 'Editar PG' : 'Cadastrar PG',
    });
  }, [isEditing]);

  useEffect(() => {
    valueSetter();
  }, [formattedGroup]);

  const changeSelectedType = (selectedType: any): void => {
    setFormattedGroup(previousState => ({
      ...previousState,
      type: selectedType,
    }));
    valueSetter();
  };

  const showSelectedLeader = (receivedParams: any): void => {
    setFormattedGroup(prevState => ({
      ...prevState,
      name: receivedParams.name,
      leader_id: receivedParams.person_id,
    }));
    valueSetter();
  };

  const acceptOneDay = (selectedDay: any): void => {
    setFormattedGroup(prevState => ({ ...prevState, day: selectedDay }));
    valueSetter();
  };

  const editingGroup = async (data: any): Promise<void> => {
    const formattedDataToBackend = {
      group_id: data._id,
      newType: data.type,
      newAddress: data.address,
      newDay: data.day,
      newAddressNeighborhood: data.address_neighborhood,
      newAddressZipCode: data.address_zipCode,
      newSchedule: format(data.scheduled, 'HH:mm'),
    };
    const dataToChangeLeader = {
      group_id: data._id,
      person_id: formattedGroup.leader_id,
      new_role: 'Líder',
    };
    await editGroup(formattedDataToBackend);
    await modifyPersonRole(dataToChangeLeader);
    navigation.goBack();
  };
  const postingGroup = async (data: IGroupData): Promise<void> => {
    await createGroup({
      ...data,
      scheduled: format(data.scheduled as Date, 'HH:mm'),
    });
    navigation.goBack();
  };
  return (
    <KeyboardAwareScrollView
      enableAutomaticScroll
      style={{
        paddingBottom: 16,
        paddingHorizontal: 16,
        flex: 1,
        backgroundColor: 'white',
      }}
    >
      <FormControlInput
        errors={errors}
        control={control}
        label="Líder do Pequeno Grupo"
        name="leader_id"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            InputRightElement={
              <Pressable
                mr={5}
                onPress={() => {
                  navigation.navigate('LinkLeaderToGroup');
                  setValue('leader_id', formattedGroup.leader_id);
                }}
              >
                <Icon
                  size={6}
                  as={<AntDesign name="pluscircleo" color="black" />}
                />
              </Pressable>
            }
            editable={false}
            onBlur={onBlur}
            size={'xl'}
            value={formattedGroup.name}
            onChangeText={onChange}
            placeholder="Nome do Líder"
          />
        )}
      />
      <FormControlInput
        errors={errors}
        control={control}
        label="Rede"
        name="type"
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            selectedValue={
              params !== undefined && formattedGroup.type === ''
                ? params.type
                : formattedGroup.type
            }
            accessibilityLabel="Selecione a Rede"
            placeholder="Selecione a Rede"
            size={'xl'}
            _selectedItem={{
              endIcon: <CheckIcon size="5" />,
            }}
            onValueChange={selectedOption => {
              changeSelectedType(selectedOption);
            }}
            _actionSheet={{
              useRNModal: Platform.OS === 'ios',
            }}
          >
            {formattedTypeOptions.map(option => (
              <Select.Item
                label={option.types}
                value={option.types}
                key={option.index}
              />
            ))}
          </Select>
        )}
      />
      <FormControlInput
        errors={errors}
        control={control}
        label="Dia do PG"
        name="day"
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            selectedValue={formattedGroup.day}
            accessibilityLabel="Selecione o Dia"
            placeholder="Selecione o Dia"
            size={'xl'}
            _selectedItem={{
              endIcon: <CheckIcon size="5" />,
            }}
            onValueChange={selectedDay => {
              acceptOneDay(selectedDay);
            }}
            _actionSheet={{
              useRNModal: Platform.OS === 'ios',
            }}
          >
            {formattedDaysOfTheWeek.map(selectedDay => (
              <Select.Item
                label={selectedDay.days}
                value={selectedDay.days}
                key={selectedDay.index}
              />
            ))}
          </Select>
        )}
      />
      <FormControlInput
        errors={errors}
        control={control}
        label={'Horário'}
        name={'scheduled'}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputMask
            customTextInput={Input}
            onBlur={onBlur}
            onChangeText={onChange}
            type="custom"
            keyboardType="numeric"
            value={value?.toString()}
            customTextInputProps={{
              size: 'xl',
            }}
            options={{
              mask: '99:99',
            }}
            placeholder="Digite o horário da reunião"
          />
        )}
      />
      <FormControlInput
        errors={errors}
        control={control}
        label="CEP"
        name="address_zipCode"
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputMask
            customTextInput={Input}
            onBlur={onBlur}
            onChangeText={onChange}
            type="custom"
            keyboardType="number-pad"
            value={value?.toString()}
            customTextInputProps={{
              size: 'xl',
            }}
            options={{
              mask: '99999-999',
            }}
            placeholder="Digite o CEP opcionalmente"
          />
        )}
      />
      <FormControlInput
        errors={errors}
        control={control}
        label="Bairro"
        name="address_neighborhood"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onBlur={onBlur}
            size={'xl'}
            value={value?.toString()}
            onChangeText={onChange}
            onChange={() => valueSetter()}
            placeholder="Insira o bairro onde ocorrerá o PG aqui"
          />
        )}
      />
      <FormControlInput
        errors={errors}
        control={control}
        label="Endereço"
        name="address"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onBlur={onBlur}
            size={'xl'}
            value={value?.toString()}
            onChangeText={onChange}
            onChange={() => valueSetter()}
            placeholder="Insira o endereço onde ocorrerá o PG aqui"
          />
        )}
      />
      <Button
        onPress={
          isEditing ? handleSubmit(editingGroup) : handleSubmit(postingGroup)
        }
        backgroundColor="blue.500"
        size="lg"
        w="100%"
        mt="3"
      >
        {isEditing ? 'Salvar' : 'Criar'}
      </Button>
    </KeyboardAwareScrollView>
  );
};

export default GroupForm;
