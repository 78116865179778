import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetchGroupList } from '@group/services';
import getGroupLeader from '@group/utils/getGroupLeader';
import { IGroupData } from '@types';


function removeDiacritics(str: string): string {
  return str
    .normalize('NFD')  
    .replace(/[\u0300-\u036f]/g, '') 
    .toLowerCase(); 
}


export const useGroupsListQuery = () => {
  const [search, setSearch] = useState('');
  const [groups, setGroups] = useState<IGroupData[]>([]);

  const { data, isLoading } = useSWR(`groups`, fetchGroupList, {
    onSuccess: setGroups,
  });

  useEffect(() => {
    if (search) {
      const filteredGroups = data.filter(
        (group: IGroupData) =>
          removeDiacritics(getGroupLeader(group)
            .name.toLowerCase())
            .includes(removeDiacritics(search.toLowerCase())) ||
          removeDiacritics(group.type.toLowerCase()).includes(removeDiacritics(search.toLowerCase())) ||
          removeDiacritics(group.day.toLowerCase()).includes(removeDiacritics(search.toLowerCase())) ||
          removeDiacritics(group.address.toLowerCase()).includes(removeDiacritics(search.toLowerCase())),
      );
      setGroups(filteredGroups);
    } else if (search === '') {
      setGroups(data);
    }
  }, [search]);

  return {
    groups,
    search,
    setSearch,
    isLoading,
  };
};
