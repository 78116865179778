import useSWR from 'swr';
import { fetchGroup } from '@group/services';

export const useGetOneGroup = (groupId: string) => {
  const { data, isLoading, error } = useSWR([`groups`, groupId], () => fetchGroup(groupId));
  return {
    group: data,
    error,
    isLoading
  };
};
