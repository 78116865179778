import { getCountGroup } from '../services';
import useSWR from 'swr';

export const useCountGroups = () => {
  const { isLoading, data } = useSWR(
    'countGroups',
    async () => await getCountGroup(),
  );
  return { data, isLoading };
};
