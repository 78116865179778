import {MaterialIcons} from "@expo/vector-icons";

interface TabBarIconProps {
  name: keyof typeof MaterialIcons.glyphMap;
  focused: boolean
}

export default function TabBarIcon({focused, name}: TabBarIconProps) {
  return (
    <MaterialIcons
      name={name}
      size={26}
      style={{ marginBottom: -3 }}
      color={focused ? 'black' : 'gray'}
    />
  );
}
