import { Box, Button, Input, Text } from "native-base";
import { authStore } from "../store";
import { useState } from "react";
import { Image } from "native-base";

export const CodeScrenn = () => {
  const [phone, setPhone] = useState("");

  return (
    <Box flex={1} alignItems="center" justifyContent="center" m={12}>
      <Image
        resizeMode="stretch"
        source={{
          uri: "https://igrejasaldaterra.org/wp-content/uploads/2020/06/cropped-logo-sal-da-terra-preta.png",
        }}
        alt="SdtImg"
        width="100%"
        height={20}
      />

      <Input
        margin={8}
        borderRadius={5}
        borderColor={"black"}
        size="2xl"
        width="100%"
        backgroundColor="transparent"
        focusOutlineColor="#888"
        placeholderTextColor={"black"}
        placeholder="Codigo do Token "
        onChangeText={setPhone}
        value={phone}
      />

      <Button
        backgroundColor={"black"}
        color={"black"}
        width="100%"
        size="lg"
      >
        Entrar
      </Button>
    </Box>
  );
};
