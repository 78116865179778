import { addPersonToGroup, fetchPersonGroups } from '../services';
import { mutate } from 'swr';

export const useAddGroup = () => {
  const addGroup = async (groupId: string, personId: string) => {
    await addPersonToGroup(groupId, personId);
    await mutate(['personGroups', personId]);
  };

  return { addGroup };
};
