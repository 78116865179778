import React from 'react';
import { observer } from 'mobx-react-lite';
import PersonAddGroupItem from '../../components/person-add-group-item';
import { FlatList, Input, VStack } from 'native-base';
import { useNavigation, useRoute } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import { useAvailableGroupsToAdd } from '../../hooks/useAvailableGroupsToAdd';
import { useAddGroup } from '../../hooks/useAddGroup';

const PersonAddGroupComponent = () => {
  const navigation = useNavigation();
  const routes = useRoute();
  const person = routes.params;

  const { availableGroups, setSearch, search } =
    useAvailableGroupsToAdd(person);

  const { addGroup } = useAddGroup();

  const onSelectGroup = async (group: any) => {
    await addGroup(group._id, person?._id);
    navigation.goBack();
  };

  const renderItem = ({ item }: any) => (
    <TouchableOpacity onPress={async () => await onSelectGroup(item)}>
      <PersonAddGroupItem group={item} />
    </TouchableOpacity>
  );

  return (
    <VStack>
      <Input
        placeholder="Pesquise por um grupo"
        placeholderTextColor="#888"
        value={search}
        onChangeText={setSearch}
        size="2xl"
        backgroundColor="white"
      />

      <FlatList data={availableGroups} renderItem={renderItem} />
    </VStack>
  );
};

export const PersonAddGroup = observer(PersonAddGroupComponent);
