import { Box, FlatList } from 'native-base';
import { ListRenderItem } from 'react-native';
import { useCountPersons } from '../hooks/useCountPersons';
import { useCountGroups } from '../hooks/useCountGroups';
import { SummaryCard } from './summary-card';

export const SummaryList = (): JSX.Element => {
  const QTD_PESSOAS_E_PGS = [
    { id: 1, count: useCountPersons().data, message: 'pessoas cadastradas' },
    { id: 2, count: useCountGroups().data, message: 'PGs cadastrados' },
  ];
  const renderItem: ListRenderItem<any> = ({ item }) => {
    return <SummaryCard count={item.count} message={item.message} />;
  };
  return (
    <Box my={4} alignItems="center">
      <FlatList
        data={QTD_PESSOAS_E_PGS}
        keyExtractor={item => item.id.toString()}
        renderItem={renderItem}
        horizontal
        showsHorizontalScrollIndicator={false}
      />
    </Box>
  );
};
