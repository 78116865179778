import { get } from '../../base/services/api';
import { IEventData } from '@types';

export const getActivityList = async (documentsToDisplay: number, actualPage: number): Promise<IEventData[]> => {
    const { data } = await get(`/recents/${documentsToDisplay};${actualPage}`)
    return data
}
export const getRecentEvents = async () => {
    const { data } = await get(`/events/recents/3;1`)
    return data
}