import { editPersonService } from '../services';
import { mutate } from 'swr';
import { IPersonData } from '../../../types';
import { useMutatePartialKey } from '@base/hooks/useMutatePartialKey';

export const useEditPerson = () => {
  const { mutatePartial } = useMutatePartialKey('persons');
  const editPerson = async (person: IPersonData) => {
    await editPersonService(person);
    mutatePartial();
  };

  return { editPerson };
};
