import { MaterialIcons } from '@expo/vector-icons';
import { Pressable, useDisclose, Actionsheet } from 'native-base';
import { useNavigation } from '@react-navigation/native';

export const PlusBarIcon = () => {
  const navigation = useNavigation();

  const { isOpen, onOpen, onClose } = useDisclose();

  const goToPersonForm = () => {
    // FIXME: Arrumar os tipos das navegações
    // @ts-expect-error
    navigation.navigate('PersonForm');
    onClose();
  };

  const goToGroupForm = () => {
    // FIXME: Arrumar os tipos das navegações
    // @ts-expect-error
    navigation.navigate('GroupForm');
    onClose();
  };

  return (
    <Pressable
      borderRadius={20}
      borderColor="#000"
      bg="#000"
      onPress={onOpen}
      onLongPress={goToPersonForm}
    >
      <MaterialIcons name={'add'} size={40} color="white" />
      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <Actionsheet.Content>
          <Actionsheet.Item onPress={goToPersonForm}>
            Cadastrar Pessoas
          </Actionsheet.Item>
          <Actionsheet.Item onPress={goToGroupForm}>
            Cadastrar PG
          </Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet>
    </Pressable>
  );
};
